<template>
  <div>
    <el-row>
      <el-table
          :data="buttonArray"
          border
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          v-loading="loading"
          element-loading-text="拼命加载中"
          style="width: 99%;margin: 1% auto">
        <el-table-column prop="buttonName" label="按钮名称" align="center"></el-table-column>
        <el-table-column prop="moduleName" label="所属页面" align="center"></el-table-column>
        <el-table-column prop="gradeNames" label="按钮等级" align="center"></el-table-column>
        <el-table-column label="是否分配" align="center" v-slot="scope">
          <el-tag
              :type="scope.row.isAssign === 1 ? 'success' : 'danger'"
              disable-transitions>{{ scope.row.isAssign === 1 ? "已分配" : "未分配" }}
          </el-tag>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope">
          <el-button-group>
            <el-button type="primary" size="mini" @click="edit(scope.row)">修改</el-button>
            <el-button type="success" size="mini" :disabled="scope.row.isAssign === 1" @click="assignSubmit(scope.row)">一键分配</el-button>
          </el-button-group>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <el-dialog
        title="按钮等级修改"
        width="25%"
        top="25vh"
        :visible.sync="editState"
        :modal-append-to-body="false"
        @close="editClose"
        v-if="editState">
      <el-form label-width="100px" :model="editForm" :rules="rules" ref="form">
        <el-row>
          <el-col>
            <el-col :span="20">
              <el-form-item label="按钮等级" prop="gradeIds">
                <el-select v-model="editForm.gradeIds" multiple placeholder="课程类型" style="width: 100%">
                  <el-option
                      v-for="item in gradeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClose" size="small">取 消</el-button>
        <el-button type="primary" @click="editSubmit" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "pc-button-list",
  created() {
    this.queryProductGradeIds()
  },
  data() {
    return {
      buttonArray: [],
      total: 0,
      page: 1,
      limit: 100,
      loading: false,
      editState: false,
      editForm: {
        id: "",
        gradeIds: [],
        originalGradeIds: [],
      },
      rules: {
        gradeIds: [{required: true, message: '请填写按钮权限等级', trigger: 'blur'}],
      },
      type: "",
      gradeList:[],
    }
  },
  watch: {
    $route:{
      handler(newRouter){
        this.type = newRouter.path.includes("pc")?1:2;
        this.queryButtonList();
      },
      immediate: true
    },
  },
  methods: {
    queryProductGradeIds() {
      this.$selectUtils.queryProductGradeIds().then(response => {
        this.gradeList = response.data.data
      })
    },
    queryButtonList() {
      this.$axios({
        method: "GET",
        url: "/button/buttonList",
        params: {
          type: this.type,
          limit: this.limit,
          page: this.page,
        }
      }).then(response => {
        console.log(response)
        this.total = response.data.data.total
        this.buttonArray = response.data.data.list
      })
    },
    edit(val) {
      this.editState = true
      this.editForm.id = val.id
      this.editForm.gradeIds = val.gradeIds
      this.editForm.originalGradeIds = val.gradeIds
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryButtonList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryButtonList()
    },
    editClose() {
      this.editState = false
    },
    editSubmit() {
      this.$axios({
        method: "PUT",
        url: "/button/updateButtonFilter",
        data: {
          ...this.editForm
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "按钮权限等级修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        if (flag) {
          this.queryButtonList()
          this.editState = false
        }
      })
    },
    assignSubmit(val) {
      this.$axios({
        method: "POST",
        url: "/button/assignButton",
        data: {
          id: val.id,
          gradeIds: val.gradeIds,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "按钮分配成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        if (flag) {
          this.queryButtonList()
        }
      })
    },
    grade(val) {
      switch (val) {
        case 1:
          return "基础版"
        case 2:
          return "经销商版"
        case 5:
          return "管理员"
      }
    },
  }
}
</script>

<style scoped>

</style>